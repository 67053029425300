import { Nav } from "./Nav";

import styles from "./Gallery.module.scss";
import { Footer } from "./Footer";

export function Gallery() {
  return (
    <>
      <section>
        <Nav />

        <div className={styles.Gallery}>
          {Array(16)
            .fill(0)
            .map((_, i) => {
              return (
                <div>
                  <img
                    alt="eggoist egg"
                    loading="lazy"
                    src={`/gallery/${i + 1}.jpg`}
                  />
                </div>
              );
            })}
        </div>
      </section>
      <Footer />
    </>
  );
}
