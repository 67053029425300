import { Link } from "react-router-dom";

export function Nav() {
  return (
    <nav className="nav">
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/winemakers">Winemakers</Link>
        </li>
        <li>
          <Link to="/gallery">Gallery</Link>
        </li>
        <li>
          <a href="mailto:eggoistwineegg@gmail.com?subject=Contact us&body=Please let us know how we can help, we'd love to answer any of your questions!">
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
}
