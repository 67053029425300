import { Nav } from "./Nav";

import "./Clients.scss";
import { AnimatedLink } from "./AnimatedLink";
import { Footer } from "./Footer";

const links = [
  { name: "Batič", img: "batic.jpeg", link: "http://www.batic-wines.com/" },
  { name: "Burja", img: "burja.png", link: "http://www.burjaestate.com/en" },
  { name: "Darjo Princic Colio", img: null, link: "" },
  {
    name: "Fedora",
    img: "fedora.svg",
    link: "https://fedorawines.com/trgovina/",
  },
  { name: "Jakoncic", img: "jakoncic.webp", link: "https://www.jakoncic.eu" },
  { name: "Joannes", img: "joannes.png", link: "http://joannes.si/en/" },
  { name: "Matic wines", img: null, link: "" },
  { name: "Movia", img: "movia.svg", link: "https://movia.si/en/wines" },
  { name: "Klabian", img: null, link: "" },
  { name: "Pasji Rep", img: "pasji-rep.png", link: "http://www.pasji-rep.si/" },
  { name: "RedHeads", img: "redheads.png", link: "https://redheadswine.com/" },
  { name: "Rizman", img: "rizmap.png", link: "https://rizman.com.hr/" },
  { name: "Santomas", img: "santomas.png", link: "https://www.santomas.si/" },
  { name: "Seppi", img: "seppi.png", link: "https://www.seppi.wine/" },
  { name: "Stemberger", img: "stemberger.png", link: "http://stemberger.si/" },
];

export function Clients() {
  return (
    <>
      <section>
        <Nav />

        <div className="Clients">
          <ul>
            {links.map((link) => {
              return (
                <li>
                  <AnimatedLink href={link.link} target="_blank">
                    {link.name}
                  </AnimatedLink>
                </li>
              );
            })}
            <li>
              <AnimatedLink href="mailto:eggoistwineegg@gmail.com?subject=Contact us&body=Please let us know how we can help, we'd love to answer any of your questions!">
                Contact us for orders
              </AnimatedLink>
            </li>
          </ul>
        </div>
      </section>
      <Footer />
    </>
  );
}
