import "./Home.scss";
import { Nav } from "./Nav";
import { AnimatedLink } from "./AnimatedLink";
import { Footer } from "./Footer";

export const Home = () => {
  const onClick = () => {
    const node = document.querySelector("#content");
    node?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Nav />
      <section className="Home">
        <div className={`Layout Layout-full`}>
          <div className="Layout-vignette">
            <div className="LineContainer">
              <div className="line gold" />
              <div className="line gold" />
              <div className="line gold" />
              <div className="line gold" />
              <div className="line gold" />
            </div>
          </div>
          <h1 className="SecretTitle">Wine Egg</h1>
          <AnimatedLink className="HeroButton" onClick={onClick}>
            read about our phylosophy
          </AnimatedLink>
          <div className="Video">
            <video autoPlay={true} playsInline={true} loop={true} muted>
              <source src="egg_video.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </section>

      <section className="Intersection" id="content">
        <p>
          <i>
            To design their first egg-shaped concrete vat, Eggoist team got
            their inspiration from the Golden Ratio. It’s a proportion initially
            defined in geometry that corresponds to the Greek letter « <b>φ</b>{" "}
            ». It is considered particularly aesthetically pleasing. An Italian
            monk brought it fame during the Renaissance in a mathe- matics
            manual and coined it the divine proportion. The Golden Ratio has
            been frequently used for centuries by figures of the likes of{" "}
            <b>Pythagoras</b>, <b>Leonardo da Vinci</b>.
          </i>
        </p>
      </section>

      <section className="Content">
        <div className="Container">
          <div className="Description">
            <p>
              <i>
                The shape of the egg is in perfect geometrical harmony. The
                natural concrete used to produce Eggoist wine egg them is
                microporous which enables the micro-oxygenation of the wine.
                They breathe and can absorb thermal shock. This means any wine
                temperature increases are gradual. The vats have no angle which
                creates a vortex providing a natural fluid movement, thereby
                providing constant wine movement. These vats are created
                specifically for the exacting wine maker who is interested in
                biodynamics and fluid energy. The egg-shaped concrete vat can be
                used as a complement to any barrel just as any vintage can be
                entirely raised in “an egg” to better preserve fruitiness and
                freshness. The aromas develop in an optimal manner and no
                stirring is necessary given the vortex movement.
              </i>
            </p>
          </div>

          <div className="Dimensions">
            <p>dimensions:</p>
            <p>
              <span>
                width: <i>1.21m</i>
              </span>{" "}
              <span>
                height: <i>1.93m</i>
              </span>{" "}
              <span>
                volume: <i>980l</i>
              </span>
            </p>
            <p>
              manhole opening: <i>300 x 420mm</i>
            </p>
            <div className="Contact">
              <AnimatedLink href="mailto:eggoistwineegg@gmail.com?subject=Contact us&body=Please let us know how we can help, we'd love to answer any of your questions!">
                Contact us for orders
              </AnimatedLink>
              <div>
                <small>or email us at eggoistwineegg@gmail.com</small>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
